import React from "react"
import styled from "@emotion/styled"
import { useIntl } from "react-intl"
import { motion } from "framer-motion"

import Link from "./Link"
import NakedButton from "./NakedButton"
import Translation from "./Translation"
import { translateMessageId } from "../utils/translations"
import ButtonLink from "./ButtonLink"

const Container = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    display: flex;
  }
`

const MenuButton = styled(NakedButton)`
  margin-left: 1rem;
`

const MobileModal = styled(motion.div)`
  position: fixed;
  background: ${(props) => props.theme.colors.modalBackground};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
`

const mobileModalVariants = {
  open: { display: "block", opacity: 1 },
  closed: { display: "none", opacity: 0 },
}

const MenuContainer = styled(motion.div)`
  background: ${(props) => props.theme.colors.background};
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  height: 100%;

  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`

const mobileMenuVariants = {
  closed: { x: `-100%`, transition: { duration: 0.2 } },
  open: { x: 0, transition: { duration: 0.8 } },
}

const GlyphButton = styled.svg`
  margin: 0 0.125rem;
  width: 1.5rem;
  height: 2.5rem;
  position: relative;
  stroke-width: 2px;
  z-index: 100;
  & > path {
    stroke: ${(props) => props.theme.colors.text};
    fill: none;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    & > path {
      stroke: ${(props) => props.theme.colors.primary};
    }
  }
`

const hamburgerSvg =
  "M 2 13 l 10 0 l 0 0 l 10 0 M 4 19 l 8 0 M 12 19 l 8 0 M 2 25 l 10 0 l 0 0 l 10 0"

const closeSvg =
  "M 2 13 l 0 -3 l 20 0 l 0 3 M 7 14 l 10 10 M 7 24 l 10 -10 M 2 25 l 0 3 l 20 0 l 0 -3"

const glyphPathVariants = {
  closed: {
    d: hamburgerSvg,
    transition: { duration: 0.4 },
  },
  open: {
    d: [hamburgerSvg, hamburgerSvg, hamburgerSvg, hamburgerSvg, closeSvg],
    transition: { duration: 1.2 },
  },
}

const MenuItems = styled.ul`
  margin: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 3rem 1rem 8rem;
`

const NavListItem = styled.li`
  margin: 0;
  margin-bottom: 3rem;
  list-style-type: none;
  list-style-image: none;
`

export const MobileNavLink = styled(Link)`
  text-decoration: none;
  margin-right: 2rem;
  margin: 1rem 0;
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
`

export interface IProps {
  isMenuOpen: boolean
  toggleMenu: (item?: "menu") => void
}

const MainMobileNav: React.FC<IProps> = ({ isMenuOpen, toggleMenu }) => {
  const intl = useIntl()

  const handleClick = (): void => {
    toggleMenu()
  }

  return (
    <Container>
      <MenuButton
        onClick={() => toggleMenu("menu")}
        aria-label={translateMessageId("aria-toggle-menu-button", intl)}
      >
        <GlyphButton viewBox="0 0 24 40">
          <motion.path
            variants={glyphPathVariants}
            initial={false}
            animate={isMenuOpen ? "open" : "closed"}
          />
        </GlyphButton>
      </MenuButton>
      <MobileModal
        animate={isMenuOpen ? "open" : "closed"}
        variants={mobileModalVariants}
        initial="closed"
        onClick={handleClick}
      />
      <MenuContainer
        aria-hidden={!isMenuOpen}
        animate={isMenuOpen ? "open" : "closed"}
        variants={mobileMenuVariants}
        initial="closed"
      >
        <MenuItems>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="/">
              <Translation id="home" />
            </MobileNavLink>
          </NavListItem>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="#about">
              <Translation id="about" />
            </MobileNavLink>
          </NavListItem>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="#how-to-get">
              <Translation id="get" />
            </MobileNavLink>
          </NavListItem>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="#mint">
              <Translation id="mint" />
            </MobileNavLink>
          </NavListItem>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="#specs">
              <Translation id="specs" />
            </MobileNavLink>
          </NavListItem>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="#wrap">
              <Translation id="wrap" />
            </MobileNavLink>
          </NavListItem>
          <NavListItem onClick={handleClick}>
            <MobileNavLink href="#roadmap">
              <Translation id="roadmap" />
            </MobileNavLink>
          </NavListItem>
          <NavListItem onClick={handleClick}>
            <ButtonLink variant="outline" href="https://app-nuts-family.netlify.app" fontWeight={"bold"} hideArrow={true}>
              <Translation id="mint-now" />
            </ButtonLink>
          </NavListItem>
        </MenuItems>
      </MenuContainer>
    </Container>
  )
}

export default MainMobileNav
